import React, { Component } from "react";
// the original library is imported locally, in the assets folder. Its not modified, the library from npm is usable also.
import Lottie from "../assets/react-lottie";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: false,
      stopRequested: false,
      showSecondMotion: false
    };
    this.stopAnimation = this.stopAnimation.bind(this);
    this.startAnimation = this.startAnimation.bind(this);
    this.completeIndicator = this.completeIndicator.bind(this);
    this.secondCompleteIndicator = this.secondCompleteIndicator.bind(this);
  }

  componentDidMount() {
    if (this.props.toggleAnimation) {
      this.startAnimation();
    } else {
      this.stopAnimation();
    }
  }
  componentWillMount() {
    this.playerOptions = {
      loop: true,
      autoplay: false,
      animationData: this.props.animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    //check for second ending motion
    if (this.props.secondData) {
      this.secondPlayerOptions = {
        loop: false,
        autoplay: false,
        animationData: this.props.secondData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    }
  }

  componentDidUpdate(prevProps) {
    // update src file if changed
    this.playerOptions.animationData = this.props.animationData;

    if (this.props.secondData) {
      this.secondPlayerOptions.animationData = this.props.secondData;
    }

    // toggle the animation
    if (prevProps.toggleAnimation == this.props.toggleAnimation) {
      return;
    } else {
      if (this.props.toggleAnimation) {
        this.startAnimation();
      } else {
        this.stopAnimation();
      }
    }
  }

  stopAnimation() {
    // console.log('stopAnimation');
    this.setState({ stopRequested: true });
  }

  startAnimation() {
    // console.log('startAnimation');
    this.setState({ stopRequested: false });
    if (this.state.isAnimating) {
      return;
    } else {
      this.setState({ isAnimating: true });
    }
  }
  //this function is triggered by the lottie component, it is triggered at the end of each loop.
  completeIndicator() {
    // console.log('the animation completed:')
    if (this.state.stopRequested) {
      this.setState({ isAnimating: false });
      //check if there is another ending secondAnimationDuration

      if (this.props.secondData) {
        this.setState({ showSecondMotion: true });
      }
    }
    if (this.props.completeIndicator) {
      this.props.completeIndicator();
    }
  }

  // triggered by the second loader
  secondCompleteIndicator() {
    console.log("secondCompleteIndicator");
    if (this.props.secondCompleteIndicator) {
      this.props.secondCompleteIndicator();
    }
  }

  render() {
    const s = this.state;
    const p = this.props;

    //check for the second animation trigger: it shows the first original loader then it shows the ending animation
    if (!s.showSecondMotion || s.isAnimating) {
      if (p.animationData) {
        // require.ensure(["../assets/react-lottie"], Lottie => {
        return (
          <Lottie
            options={this.playerOptions}
            speed={p.dataDuration / parseFloat(p.duration)}
            isPaused={!s.isAnimating}
            isStopped={!s.isAnimating}
            isClickToPauseDisabled={true}
            eventListeners={[
              {
                eventName: "loopComplete",
                callback: () => this.completeIndicator()
              }
            ]}
          />
        );
        // });
      } else return <React.Fragment></React.Fragment>;
    } else {
      if (p.secondData) {
        // require.ensure(["../assets/react-lottie"], Lottie => {
        return (
          <Lottie
            options={this.secondPlayerOptions}
            isStopped={false}
            speed={p.secondDataDuration / parseFloat(p.secondDuration)}
            isClickToPauseDisabled={true}
            eventListeners={[
              {
                eventName: "loopComplete",
                callback: () => this.secondCompleteIndicator()
              }
            ]}
          />
        );
        // });
      } else return <React.Fragment></React.Fragment>;
    }
  }
}

Loader.defaultProps = {
  toggleAnimation: false,
  duration: 10.0,
  dataDuration: 10.0,
  animationData: null,
  completeIndicator: null,
  secondCompleteIndicator: null,
  secondData: null,
  secondDuration: 10.0,
  secondDataDuration: 10.0
};

export default Loader;
